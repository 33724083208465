<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, Switch } from '@headlessui/vue'
import { CalendarIcon, PhoneIcon, EnvelopeIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/outline';
import { QuestionMarkCircleIcon, MagnifyingGlassPlusIcon, MagnifyingGlassMinusIcon } from '@heroicons/vue/24/solid';
import { useClubStore } from '../../../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useMember } from '../../../../js/store/member';
import KycModel from '../../front-end/club/KycModel.vue'

const appUrl = window.location.origin

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const memberStore = useMember()

const props = defineProps({
  id: { type: Number, required: true }
})

const refKycModal = ref(null)

const isSaved = ref(false)
const isOpen = ref(false)
const open = () => {
  isSaved.value = false
  isOpen.value = true
}
const close = () => {
  isSaved.value = false
  isOpen.value = false
}

const error = ref({})
const save = async () => {
  error.value = {}
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    return
  }

  memberStore.saved = true
  isSaved.value = true
  const requiredNameFields = ['surname', 'name', 'romaji_surname', 'romaji_name'];
  let checkName = false
  requiredNameFields.forEach(field => {
    if (!memberStore.member[field]) {
      checkName = true
      return
    }
  });
  if (checkName) {
    $root.push.error('名前を入力してください')
    return
  }

  if (!memberStore.member.phone && !memberStore.member.phone_check) {
    $root.push.error('電話番号を入力してください')
    return
  }
  //validate phone exist
  if (memberStore.member.phone) {
    $root.overlay.loading = true
    const result = await memberStore.validatePhone(memberStore.member.phone)
    if (result) {
      $root.overlay.loading = false
      error.value.phone_exist = true
      $root.push.error('この電話番号がすでに登録されました。')
      return
    }
  }

  $root.overlay.loading = true
  try {
    await memberStore.save(clubStore.item.id)
    $root.push.success('メンバーを保存しました')

    // update member list
    clubStore.getMembers()

    close()
  } catch (error) {
    $root.push.error('メンバーの保存に失敗しました')
  }
  $root.overlay.loading = false
}

const changeKycHandler = (e) => {
  const file = e.target.files[0]
  if (!file) {
    return
  }
  if (file.size > 5 * 1024 * 1024) {
    $root.push.error('※アップロードできるファイルサイズは5MBまでです。')
    return
  }

  const reader = new FileReader()
  reader.onload = (e) => memberStore.member.kyc = e.target.result
  reader.readAsDataURL(file)
}
const phoneInput = () => {
  memberStore.member.phone = memberStore.member.phone.replace(/[^0-9]/g, '');
}

const zoomedKyc = ref(false)
const kycImageUrl = computed(() => {
  if (memberStore.member.file) {
    return memberStore.member.file
  }

  if (memberStore.member.kyc) {
    return memberStore.member.kyc
  }

  return '/kyc.png'
})
const isDefaultKyc = computed(() => {
  return !memberStore.member.file && !memberStore.member.kyc
})

const qrCodeImageUrl = computed(() => {
  if (memberStore.member.qr_code_image) {
    return memberStore.member.qr_code_image
  }

  return '/qrcode-placeholder.png'
})
const isDefaultQrcode = computed(() => {
  return !memberStore.member.qr_code_image
})

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 md:p-2 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary">
              <!-- add member to club #A -->
              <DialogTitle as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 py-0.5 px-2 bg-graysp justify-between sticky top-0 z-20">
                {{ memberStore.member.id > 0 ? 'メンバー詳細' : 'メンバー追加' }}
                <div>
                  <button title="閉じる" class="transition duration-300 delay-75 ease-in-out" @click="close">
                    <XMarkIcon class="w-6 h-6 text-gray-400" @click="close" />
                  </button>
                </div>
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <div class="flex flex-col gap-2 px-4 py-2">
                  <!-- name -->
                  <div class="flex flex-row gap-2">
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        姓
                        <span class="text-error">*</span>
                      </label>
                      <input type="text" class="px-2 py-1 border border-gray-200 rounded" :class="{
                        'bg-red/10 border-red': isSaved && !memberStore.member.surname
                      }" v-model.trim="memberStore.member.surname" />
                    </div>
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        名
                        <span class="text-error">*</span>
                      </label>
                      <input type="text" class="px-2 py-1 border border-gray-200 rounded" :class="{
                        'bg-red/10 border-red': isSaved && !memberStore.member.name
                      }" v-model.trim="memberStore.member.name" />
                    </div>
                  </div>
                  <!-- Romaji name -->
                  <div class="flex flex-row gap-2">
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        姓(ローマ字)
                        <span class="text-error">*</span>
                      </label>
                      <input type="text" class="px-2 py-1 border border-gray-200 rounded" :class="{
                        'bg-red/10 border-red': isSaved && !memberStore.member.romaji_surname
                      }" v-model.trim="memberStore.member.romaji_surname" />
                    </div>
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        名(ローマ字)
                        <span class="text-error">*</span>
                      </label>
                      <input type="text" class="px-2 py-1 border border-gray-200 rounded" :class="{
                        'bg-red/10 border-red': isSaved && !memberStore.member.romaji_name
                      }" v-model.trim="memberStore.member.romaji_name" />
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row md:items-center gap-2">
                    <!-- phone -->
                    <div class="flex-none w-full md:w-1/2">
                      <div class="flex flex-col w-full">
                        <label class="flex items-center gap-1">
                          電話番号(ハイフンなし)
                          <span class="text-error" v-if="!memberStore.member.phone_check">*</span>
                        </label>
                        <div class="relative w-full">
                          <PhoneIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                          <input type="text" class="px-2 py-1 border border-gray-200 rounded w-full pl-8" :class="{
                            'bg-red/10 border-red': isSaved && !memberStore.member.phone_check && !memberStore.member.phone
                          }" v-model.trim="memberStore.member.phone" placeholder="0900000000" @input="phoneInput" />
                        </div>
                      </div>
                    </div>
                    <!-- email -->
                    <div class="flex flex-col w-full md:w-1/2">
                      <label>メールアドレス</label>
                      <div class="relative w-full">
                        <EnvelopeIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                        <input type="email" class="px-2 py-1 border border-gray-200 rounded w-full pl-8"
                          v-model.trim="memberStore.member.email" placeholder="name@example.com" />
                      </div>
                    </div>
                  </div>
                  <div v-if="error.phone_exist" class="flex items-end gap-2">
                    <div class="font-semibold text-sm text-error">この電話番号がすでに登録されました。</div>
                  </div>

                  <div class="flex items-end gap-2">
                    <!-- gender -->
                    <div class="flex flex-col">
                      <label>性別</label>
                      <div class="w-fit">
                        <select class="px-2 py-1 border border-gray-200 rounded w-fit"
                          v-model="memberStore.member.gender">
                          <option value="male">男性</option>
                          <option value="female">女性</option>
                          <option value="other">その他</option>
                        </select>
                      </div>
                    </div>
                    <!-- dob -->
                    <div class="flex flex-col w-fit">
                      <label>生年月日</label>
                      <div class="max-w-40">
                        <VueDatePicker v-model="memberStore.member.dob" auto-apply format="yyyy-MM-dd"
                          :enable-time-picker="false" locale="ja"
                          input-class-name="px-2 py-1 border border-gray-200 rounded">
                          <template #input-icon>
                            <CalendarIcon class="ml-2 w-4 h-4" />
                          </template>
                          <template
                            #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
                            <input type="text" :value="value"
                              class="pl-8 pr-2 py-1 border border-gray-200 rounded max-w-40"
                              placeholder="yyyy-mm-dd" />
                          </template>
                        </VueDatePicker>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-flow-row md:grid-cols-2 gap-2 transition-all duration-300 delay-75 ease-in-out">
                    <!-- KYC -->
                    <fieldset class="border border-gray-200 rounded p-2 transition-all duration-300 delay-75 ease-in-out">
                      <legend class="font-semibold bg-gray-100 px-2 py-0.5 rounded">KYC</legend>
                      <div class="flex flex-col h-full gap-2 transition-all duration-300 delay-75 ease-in-out">
                        <div class="grow transition-all duration-300 delay-75 ease-in-out">
                          <div class="flex w-full h-full justify-center items-center border border-gray-200 rounded group relative overflow-hidden transition-all duration-300 delay-75 ease-in-out">
                            <div class="max-w-full max-h-full transition-all duration-300 delay-75 ease-in-out flex justify-center"
                              :class="{
                                'opacity-30': isDefaultKyc,
                              }"
                              @click.prevent="zoomedKyc = !zoomedKyc"
                            >
                              <img 
                                :src="kycImageUrl" 
                                class="object-cover cursor-pointer transition-all duration-300 delay-75 ease-in-out" 
                                :class="{
                                  'w-full h-auto': zoomedKyc,
                                  'w-2/3 max-w-full h-auto my-2': !zoomedKyc
                                }"
                                alt="KYC" 
                                title="KYC" 
                                loading="lazy" 
                              />
                              <div class="absolute inset-0 hidden md:flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300 delay-75 ease-in-out">
                                <button class="bg-gray-200 p-2 rounded-md">
                                  <MagnifyingGlassPlusIcon v-if="!zoomedKyc" class="w-8 h-8 text-black" />
                                  <MagnifyingGlassMinusIcon v-else class="w-8 h-8 text-black" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="h-fit flex-none">
                          <div class="flex flex-col gap-2">
                            <div class="flex gap-4 justify-center items-center">
                              <label class="px-2 py-0.5 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out flex items-center gap-1 w-fit cursor-pointer">
                                <input type="file" class="hidden" accept="image/png, image/jpeg, image/jpg"
                                  @change="changeKycHandler" />
                                <ArrowUpTrayIcon class="w-4 h-4" />
                                アップロード
                              </label>
                              <button class="flex items-center gap-1 px-2 py-0.5 border border-slate-400 rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                                @click.prevent="$refs.refKycModal.open"
                              >
                                <QuestionMarkCircleIcon class="w-4 h-4 fill-orange-500" />
                                KYCとは？
                              </button>
                            </div>
                            <div class="flex flex-col text-gray-400 text-xxs leading-tight">
                              <div>※最大5MBまでのアップロードが可能です。</div>
                              <div>※画像のみ対応しています。</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <!-- QRCODE -->
                    <fieldset class="border border-gray-200 rounded p-2 items-end transition-all duration-300 delay-75 ease-in-out">
                      <legend class="font-semibold bg-gray-100 px-2 py-0.5 rounded">
                        QRコード
                      </legend>
                      <div class="flex flex-col h-full gap-2 transition-all duration-300 delay-75 ease-in-out">
                        <div class="grow">
                          <div class="flex w-full justify-center transition-all duration-300 delay-75 ease-in-out">
                            <div class="w-fit h-fit border border-gray-200 rounded p-0.5 overflow-hidden transition-all duration-300 delay-75 ease-in-out"
                              :class="{
                                'opacity-30': isDefaultQrcode,
                              }"
                            >
                              <img 
                                :src="qrCodeImageUrl"
                                class="w-44 h-44 object-cover rounded cursor-pointer transition-all duration-300 delay-75 ease-in-out" 
                                alt="QRコード" 
                                title="QRコード" 
                                loading="lazy" 
                              />
                            </div>
                          </div>
                        </div>
                        <div class="h-fit flex-none">
                          <div class="flex flex-col gap-2">
                            <div class="flex items-end gap-8">
                              <div class="flex flex-col">
                                有効期限
                                <div class="max-w-40">
                                  <VueDatePicker 
                                    v-model="memberStore.member.expired_at" 
                                    locale="ja" 
                                    format="yyyy-MM-dd"
                                    :enable-time-picker="false" 
                                    auto-apply 
                                    teleport
                                  >
                                    <template #input-icon>
                                      <CalendarIcon class="ml-2 w-4 h-4" />
                                    </template>
                                    <template #dp-input="{ value }">
                                      <input type="text" :value="value"
                                        class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-40"
                                        placeholder="yyyy-mm-dd" />
                                    </template>
                                  </VueDatePicker>
                                </div>
                              </div>
                              <label class="flex flex-col gap-1 cursor-pointer">
                                有効・無効
                                <Switch v-model="memberStore.member.status"
                                  class="relative inline-flex h-6 w-11 items-center rounded-full mb-1" 
                                  :class="{
                                    'bg-primary': memberStore.member.status,
                                    'bg-gray-200': !memberStore.member.status
                                  }"
                                >
                                  <span class="inline-block h-4 w-4 transform rounded-full bg-white transition" 
                                    :class="{
                                      'translate-x-6': memberStore.member.status,
                                      'translate-x-1': !memberStore.member.status
                                    }" 
                                  />
                                </Switch>
                              </label>
                            </div>
                            <div class="text-xxs text-gray-400 leading-tight">
                              ※有効期限を過ぎると使用できなくなります。
                              <div>※有効期限を設定しない場合は無期限です。</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <!-- note -->
                  <div class="flex flex-col">
                    <label>備考</label>
                    <textarea 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      rows="3"
                      v-model.trim="memberStore.member.note"
                    ></textarea>
                  </div>
                </div>

                <div class="flex items-center justify-between mt-2 w-full bg-gray-50 p-4 border-t sticky bottom-0 z-20">
                  <div></div>
                  <div class="flex items-center gap-2">
                    <!-- cancel -->
                    <button
                      class="px-4 py-1 bg-white border rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="close">
                      キャンセル
                    </button>
                    <!-- add guest -->
                    <button
                      class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="save">
                      保存
                    </button>
                  </div>
                </div>
              </div>
              <KycModel ref="refKycModal" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
