<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { PlusIcon, MinusCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useClubRequestStore } from '../../../../js/store/club-request';
import { useResource } from '../../../../js/store/resource';
import DateTimeHelper from '../../../../js/helper/DatetimeHelper.js';
import InputTime from '../../../components/InputTime.vue';

const emit = defineEmits(['update'])
const $root = getCurrentInstance()?.appContext.config.globalProperties
const resourceStore = useResource()
const clubRequestStore = useClubRequestStore()
const props = defineProps({
  items: { type: Array, default: () => [] },
})

const year = ref(0)
const month = ref(0)
const day = ref(0)
const info = ref({})
const item = ref({
  selected: false,
  schedules: [
    {
      selected: true,
      room_id: 0,
      start: '',
      end: ''
    }
  ]
})
const isOpen = ref(false)

const open = (obj, infoDay) => {
  year.value = obj.year
  month.value = obj.month
  day.value = obj.day

  if (obj.item) {
    const newItem = JSON.parse(JSON.stringify(obj.item))
    item.value = Object.assign({}, {
      ...newItem,
      selected: true
    })
  }

  if (infoDay) {
    info.value = infoDay
  }

  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const addRoom = () => {
  item.value.schedules.push({
    room_id: defaultRoomId.value,
    start: basicStart.value,
    end: basicEnd.value,
    selected: true
  })
}

const removeRoom = (idx) => {
  // if status is approved, cannot remove
  if (item.value.schedules[idx].status === 'approved') {
    $root.push.error('承認済みのため削除できません')
    return
  }

  // remove item if length is more than 1
  if (item.value.schedules.length === 1) {
    $root.push.error('1件以上の設定が必要です')
    return
  }

  item.value.schedules.splice(idx, 1)
}

const save = () => {
  // Validation
  const schedules = item.value.schedules
  // check if end time is earlier than start time
  const invalid = schedules.some(s => {
    return DateTimeHelper.isSameOrAfter(s.start, s.end)
  })
  if (invalid) {
    $root.push.error('終了時間は開始時間より後に設定してください')
    return
  }

  // check duplicate with same room_id and (start and end time are telescopically overlapped)
  const duplicate = schedules.some((s, i) => {
    return schedules.slice(i + 1).some(s2 => {
      return s.room_id === s2.room_id && 
        DateTimeHelper.isTelescopicallyOverlapped(s.start, s.end, s2.start, s2.end)
    })
  })
  if (duplicate) {
    $root.push.error('同じ施設と時間帯が重複しています')
    return
  }

  // TODO:
  // check if start time is earlier than basic start time
  // check if end time is later than basic end time

  emit('update', {
    year: year.value,
    month: month.value,
    day: day.value,
    schedules: item.value.schedules
  })

  close()
}

const dateOfWeek = computed(() => {
  const m = month.value?.toString().padStart(2, '0')
  const d = day.value?.toString().padStart(2, '0')
  return DateTimeHelper.getDayOfWeekShort(`${year.value}-${m}-${d}`)
})
const defaultRoomId = computed(() => {
  return clubRequestStore.rooms[0]?.id || 0
})
const basicStart = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '平日')?.opening_time
  return time ? time.slice(0, 5) : ''
})
const basicEnd = computed(() => {
  const time = resourceStore.itemsTime?.find(t => t.name_day === '平日')?.closing_time
  return time ? time.slice(0, 5) : ''
})

const getRangeByRoomId = (roomId) => {
  const room = info.value.rooms.find(r => r.id === roomId)
  if (!room) {
    return { 
      opening_time: '0:00', 
      closing_time: '23:59'
    }
  }

  return {
    opening_time: room.opening_time,
    closing_time: room.closing_time
  }
}

defineExpose({ open, close })
</script>
<template>
  <!-- 利用申請‐個別設定‐ -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-20">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-fit transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <DialogTitle class="flex items-center justify-between gap-4 px-4 py-2 bg-graysp text-primary font-semibold text-lg">
                利用申請‐個別設定
              </DialogTitle>
              <div class="flex flex-col gap-4 p-4">
                <div class="flex flex-col md:flex-row md:items-center gap-4 md:justify-between">
                  <div class="text-black font-semibold text-lg">
                    {{ year }}年{{ month }}月{{ day }}日（{{ dateOfWeek }}）
                  </div>
                  <div class="flex flex-col md:flex-row md:gap-2 md:items-center text-sm">
                    利用可否情報
                    <div class="border px-2 py-1 flex flex-col leading-tight">
                      <table>
                        <tbody>
                          <tr v-for="r in info?.rooms">
                            <td>{{ r.name }}</td>
                            <td class="px-1">
                              <div class="flex items-center gap-2">
                                {{ r.closed ? '✕' : (r.default ? '◯' : r.time) }}
                                <span class="max-w-32 truncate" :title="r.note">{{ r.note }}</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <table class="w-full">
                    <thead>
                      <tr>
                        <th class="font-normal text-sm p-1 text-left">施設選択</th>
                        <th class="font-normal text-sm p-1 text-left">利用時間</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="p-1">
                          <div class="flex flex-col gap-1">
                            <div v-for="schedule in item.schedules" class="h-8 flex items-center">
                              <select class="w-full border border-gray-200 rounded px-2 py-0.5" 
                                v-model="schedule.room_id"
                                :disabled="schedule.status === 'approved'"
                                :class="{ 'bg-gray-100': schedule.status === 'approved' }"
                              >
                                <template v-for="room in clubRequestStore.rooms">
                                  <option 
                                    :value="room.id"
                                    v-if="!info.rooms.find(r => r.id === room.id)?.closed"
                                  >
                                    {{ room.name }}
                                  </option>
                                </template>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td class="p-1 w-10">
                          <div class="flex flex-col gap-1">
                            <div v-for="(schedule, idx) in item.schedules" class="flex items-center gap-1 h-8">
                              <div>
                                <InputTime 
                                  v-model="schedule.start" 
                                  :stepminute="15" 
                                  :disabled="schedule.status === 'approved'"
                                  :class="{
                                    'bg-error/30': DateTimeHelper.isSameOrAfter(schedule.start, schedule.end)
                                  }"
                                  :range="getRangeByRoomId(schedule.room_id)"
                                />
                              </div>
                              ~
                              <div>
                                <InputTime 
                                  v-model="schedule.end" 
                                  :stepminute="15" 
                                  :disabled="schedule.status === 'approved'"
                                  :class="{
                                    'bg-error/30': DateTimeHelper.isSameOrAfter(schedule.start, schedule.end)
                                  }"
                                  :range="getRangeByRoomId(schedule.room_id)"
                                />
                              </div>
                              <button 
                                class="hover:opacity-80" 
                                @click.prevent="removeRoom(idx)"
                                :title="schedule.status === 'approved' ? '承認済みのため削除できません' : '削除'"
                              >
                                <CheckCircleIcon v-if="schedule.status == 'approved'" class="w-6 h-6 fill-blue-500" />
                                <MinusCircleIcon v-else class="w-6 h-6 text-error" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="p-1" colspan="2">
                          <button class="w-full flex justify-center px-4 py-1 border border-gray-300 rounded hover:opacity-80" @click.prevent="addRoom">
                            <span class="p-0.5 bg-gray-500 rounded">
                              <PlusIcon class="w-4 h-4 fill-white" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="flex gap-4 items-center font-semibold justify-center">
                  <button class="border rounded px-4 py-1.5 bg-gray-100 min-w-32 hover:opacity-80" @click.prevent="close">キャンセル</button>
                  <button class="px-4 py-1.5 bg-primary text-white rounded hover:opacity-80 min-w-32" @click.prevent="save">登録</button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
